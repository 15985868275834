let state = {
  isOpen: [], // 是否公开
  isSupport: [], // 是否支持
  sex: [], // 性别
  orgType: [], // 新建组织-组织类型
  backgroundEdu: [], // 学历
  owner: [], // 发布者
  modelType: [], // 模型类别数组
  modelTypeTree: [], // 模型类别树
  frameWork: [], // 支持框架
  supportHardware: [], // 支持硬件
  supportToolSoftware: [], // 支持软件
  applicationScenario: [], // 应用场景
  modelImages: [], // 镜像列表
  timeRangeList:[], //休眠时间
  XPUInfoList:[], //xpu信息列表
  ServerTypeList:[],
  language: [], // 语言
  label: [], // 标签
  license: [] // 许可
}

const mutations = {
  setIsOpen(state, n) {
    state.isOpen = n
  },
  setIsSupport(state, n) {
    state.isSupport = n
  },
  setSex(state, n) {
    state.sex = n
  },
  setOrgType(state, n) {
    state.orgType = n
  },
  setBackgroundEdu(state, n) {
    state.backgroundEdu = n
  },
  setOwner(state, n) {
    state.owner = n
  },
  setModelType(state, n) {
    state.modelType = n
  },
  setModelTypeTree(state, n) {
    state.modelTypeTree = n
  },
  setFrameWork(state, n) {
    state.frameWork = n
  },
  setSupportHardware(state, n) {
    state.supportHardware = n
  },
  setModelImages(state, n) {
    state.modelImages = n
  },
  setTimeRangeList(state, n) {
    state.timeRangeList = n
  },
  setXPUInfoList(state, n) {
    state.XPUInfoList = n
  },
  setServerTypeList(state, n) {
    state.ServerTypeList = n
  },
  setSupportToolSoftware(state, n) {
    state.supportToolSoftware = n
  },
  setApplicationScenario(state, n) {
    state.applicationScenario = n
  },
  setLanguage(state, n) {
    state.language = n
  },
  setLabel(state, n) {
    state.label = n
  },
  setLicense(state, n) {
    state.license = n
  }
}

const actions = {
  setIsOpen({ commit }, n) {
    commit('setIsOpen', n)
  },
  setIsSupport({ commit }, n) {
    commit('setIsSupport', n)
  },
  setSex({ commit }, n) {
    commit('setSex', n)
  },
  setOrgType({ commit }, n) {
    commit('setOrgType', n)
  },
  setBackgroundEdu({ commit }, n) {
    commit('setBackgroundEdu', n)
  },
  setOwner({ commit }, n) {
    commit('setOwner', n)
  },
  setModelType({ commit }, n) {
    commit('setModelType', n)
  },
  setModelTypeTree({ commit }, n) {
    commit('setModelTypeTree', n)
  },
  setFrameWork({ commit }, n) {
    commit('setFrameWork', n)
  },
  setSupportHardware({ commit }, n) {
    commit('setSupportHardware', n)
  },
  setModelImages({ commit }, n) {
    commit('setModelImages', n)
  },
  setTimeRangeList({ commit }, n) {
    commit('setTimeRangeList', n)
  },
  setXPUInfoList({ commit }, n) {
    commit('setXPUInfoList', n)
  },
  setServerTypeList({ commit }, n) {
    commit('setServerTypeList', n)
  },
  setSupportToolSoftware({ commit }, n) {
    commit('setSupportToolSoftware', n)
  },
  setApplicationScenario({ commit }, n) {
    commit('setApplicationScenario', n)
  },
  setLanguage({ commit }, n) {
    commit('setLanguage', n)
  },
  setLabel({ commit }, n) {
    commit('setLabel', n)
  },
  setLicense({ commit }, n) {
    commit('setLicense', n)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
