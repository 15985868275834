<template>
  <el-button
    plain
    class="text-pri"
    :disabled="disabled || !phoneValid"
    @click="getCode"
    :loading="loading"
  >
    {{ btnText }}
  </el-button>
</template>

<script>
import { valiMobilePhone } from '@/utils/validate'
import verification from '@/config/regularExpression'

export default {
  props: {
    telphone: {},
    checkPhone: {
      default: false
    }
  },
  data() {
    return {
      disabled: false,
      phoneValid: false, // 手机号合法
      countdown: 60,
      interval: null,
      loading: false
    }
  },
  computed: {
    btnText() {
      if (this.disabled && this.phoneValid) {
        return this.countdown + '秒后，重新获取验证码'
      } else {
        return '获取动态码'
      }
    }
  },
  created() {},
  mounted() {},
  watch: {
    telphone: {
      handler(val) {
        this.phoneValid = false
        if (val) {
          const { status, message } = valiMobilePhone(val)
          this.phoneValid = status
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.clearInterval()
  },
  methods: {
    async getCode() {
      if (!verification.telphone.test(this.telphone)) {
        this.$message.error('手机号不合法')
        return
      }
      let phoneIsRegister = true
      if (this.checkPhone) {
        this.loading = true
        try {
          // 校验新手机号是否已注册
          const res = await this.$api.user.checkPhone(this.telphone)
          if (res.message === 'Success') {
            phoneIsRegister = false
          }
        } catch (error) {
        } finally {
          this.loading = false
        }
      } else {
        // 不需要校验
        phoneIsRegister = false
      }

      // 手机号未被注册
      if (!phoneIsRegister) {
        this.$api.user
          .sendCode({
            phone: this.telphone
          })
          .then((res) => {
            if (res.message === 'Success') {
              this.$emit('status', 'success')
              this.disabled = true
              this.interval = setInterval(() => {
                this.countdown--
                if (this.countdown === 0) {
                  this.clearInterval()
                  this.resetBtnStatus()
                }
              }, 1000)
            } else {
              this.$emit('status', 'error')
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    resetBtnStatus() {
      this.disabled = false
      this.countdown = 2
    },
    clearInterval() {
      clearInterval(this.interval)
      this.interval = null
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
