import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'
import store from '@/store'

import navbars from '@/config/narbars'
const modulePaths = navbars.map((item) => item.path)

// 解决跳转当前路由path时console报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

router.beforeEach((to, from, next) => {
  let matchPaths = to.matched?.map((i) => i.name)
  matchPaths.forEach((i) => {
    if (modulePaths.includes('/' + i)) {
      store.commit('system/setPresentModule', '/' + i)
    }
  })
  if (to.path.includes('/model')) {
    store.dispatch('project/setType', 'M')
  } else if (to.path.includes('/dataset')) {
    store.dispatch('project/setType', 'D')
  } else if (to.path.includes('/codes')) {
    store.dispatch('project/setType', 'C')
  }
  next()
})

export default router
