import store from '@/store'

// 合法path
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

// 校验模型/数据集英文名
export function valiEngName(engName) {
  let status = true,
    message = ''
  if (!engName) {
    message = '请填写英文名称'
    status = false
  }
  if (!/^[A-Za-z0-9-_.]+$/.test(engName)) {
    message = '英文名只能用字母、数字、下划线、中划线，不能用中文。'
    status = false
  }

  return {
    status,
    message
  }
}

// 校验用户名
export function valiUsername(username) {
  let status = true,
    message = ''
  if (!username) {
    message = '用户名不能为空'
    status = false
  }
  if (username.length < 4 || username.length > 20) {
    message = '用户名长度应在4-20位之间'
    status = false
  }
  if (!/^[A-Za-z0-9]+$/.test(username)) {
    message = '用户名只能用字母和数字组合,不能用中文和特殊字符'
    status = false
  }

  return {
    status,
    message
  }
}

// 校验邮箱
export function valiEmail(email) {
  let status = true,
    message = ''

  if (!email) {
    message = '邮箱不能为空'
    status = false
  }

  // 使用正则表达式判断格式是否合法
  const emailPass = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(
    email
  )
  if (!emailPass) {
    message = '邮箱格式不合法'
    status = false
  }

  return { status, message }
}

// 校验手机号码
export function valiMobilePhone(number) {
  let status = true,
      message = ''

  if (!number) {
    message = '手机号码不能为空'
    status = false
  }

  const mobilephonePass = /^1[3-9]\d{9}$/.test(number)
  if(!mobilephonePass) {
    message = '手机号码格式错误'
    status = false
  }

  return { status, message }
}

// 校验密码
export function valiPassword(password) {
  let status = true,
    message = ''

  if (!password) {
    message = '密码不能为空'
    status = false
  }
  // 判断密码长度是否在8-12位之间
  if (password.length < 8 || password.length > 20) {
    message = '密码长度应在8-20位之间'
    status = false
  }

  // 使用正则表达式判断是否包含数字、大写字母和小写字母
  const hasNumber = /\d/.test(password)
  const hasUppercase = /[A-Z]/.test(password)
  const hasLowercase = /[a-z]/.test(password)

  if (!hasNumber || !hasUppercase || !hasLowercase) {
    message = '密码应同时包含数字、大写字母、小写字母'
    status = false
  }

  // 使用正则表达式判断是否包含特殊字符
  // const hasSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
  //   password
  // )

  // if (hasSpecialCharacter) {
  //   message = '密码不可以有特殊字符'
  //   status = false
  // }

  return {
    status,
    message
  }
}

// 校验用户信息
export function valiUser() {
  // 是否登录
  if (!store.state.system.userInfo) {
    store.dispatch('system/toLogin', true) // 显示登录弹窗
    return false
  }
  if (!store.state.system.userBaseInfo.user_name) {
    store.dispatch('system/perfectInfo', true) // 完善信息弹窗
    return false
  }
  return true
}
