import path from '@/api/path'
import service from '@/api/service'
import api from '@/api'
import store from '@/store'
import { Message } from 'element-ui'

const { user } = path

export default {
  // 登录
  login: (data) => {
    return service.post(user.login, data)
  },
  // 注册
  register: (data) => {
    return service.post(user.register, data)
  },
  // 发送验证码
  sendCode: (data) => {
    return service.post(user.sendCode, data)
  },
  // 短信验证码验证
  verifyCode: (data) => {
    return service.post(user.verifyCode, data)
  },
  // 查询用户信息
  getInfo: () => {
    return service.get(user.putInfoNeedToken)
  },
  // 账号更新(注册完成后完善信息页面用，无需token)
  putInfoNoToken: (data) => {
    return service.put(user.putInfoNoToken, data)
  },
  // 账号更新(修改基本信息用，需要token)
  putInfoNeedToken: (data) => {
    return service.put(user.putInfoNeedToken, data)
  },
  // 忘记密码
  forgetPassword: (data) => {
    return service.post(user.forgetPassword, data)
  },
  // 上传头像
  uploadAvatar: (data) => {
    return service.post(user.uploadAvatar, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  // 查询用户列表
  query: (name) => {
    if (name) {
      return service.get(user.query + '?search_name=' + name)
    } else {
      return service.get(user.query)
    }
  },
  // 验证手机号是否已注册
  checkPhone: (phone) => {
    return service.get(user.checkPhone + '?phone=' + phone)
  },
  // 保存用户基本信息到vuex
  saveUserBaseInfo: () => {
    api.user.getInfo().then((res) => {
      const { message } = res
      if (message === 'Success') {
        // 保存在vuex
        store.dispatch('system/setUserBaseInfo', res.data)
      } else {
        Message.error(message)
      }
    })
  }
}
