<template>
  <div>
    <div class="w-full header-wrapper flex items-center header-palce" style="height: 50px;">
      <img src="@/assets/images/logo.png" class="logo" />
      <Search></Search>
      <Navbar></Navbar>
      <User></User>
      <!-- <el-select v-model="languageChec" placeholder="语言" style="width: 100px; margin-left: 10px;" @change="language" >
        <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
      </el-select> -->
      
      <!-- <el-dropdown style="margin-left: 10px;">
        <span class="el-dropdown-link">
    <img src="@/assets/images/lange.png" alt="" class="w-20px h-20px" style=" display: block; float: left; margin-right: 5px;"> 简体中文
  </span>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item>
     简体中文
    </el-dropdown-item>
    <el-dropdown-item>
 English
  </el-dropdown-item>
  </el-dropdown-menu>
</el-dropdown> -->
      <!-- <p>{{ $t('message.music') }}</p> -->
    </div>
    <div class="header-palce"></div>
  </div>
</template>
<script>
import Search from './search.vue'
import Navbar from './navbar.vue'
import User from './user.vue'
export default {
  components: { Search, Navbar, User },
  data() {
    return {
      languageChec:'简体中文',
     
    }
  },
  created() {

    sessionStorage.getItem('languageType')
    if( sessionStorage.getItem('languageType')){
      let data = sessionStorage.getItem('languageType')
      console.log(sessionStorage.getItem('languageType'));
      if(data==1){
        this.languageChec='中文'
      }
      if(data==2){
        this.languageChec='English'
      }
    }
  },
  mounted() {},
  methods: {
    language(data){
      sessionStorage.setItem('languageType',data)

      if(data==1){
        this.$i18n.locale = 'zh-CN';
      }
      if(data==2){
        this.$i18n.locale = 'en-US';
      }

    }
  }
}
</script>

<style lang="scss" scoped>

.header-wrapper {
  background: #fff;
  padding: 0 181px;
  font-size: 19px;
  position: fixed;
  top: 0;
  z-index: 1000;

  .logo {
    width: 80px;
    height: 28px;
  }
}

.header-palce {

}

@media only screen and (max-width: 768px) {
  .header-wrapper {
    padding: 0 80px;

    .logo {
      width: 260px;
      height: auto;
    }
  }

  .header-palce {

  }
}
::v-deep(.el-input__icon){
  line-height: 0.14933rem;
}

</style>
