import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './assets/style/element-variables.scss' // 重写部分element组件的样式
import MetaInfo from 'vue-meta-info'
import VueI18n from 'vue-i18n';

initElementMessage()
import 'windi.css'

import './assets/style/base.scss'
import './assets/style/theme.scss'
import './assets/style/common.scss'

import './utils/viewport'
import uploader from 'vue-simple-uploader'

import '@/icons' // icon
import api from '@/api'
import dayjs from 'dayjs'

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
require('dayjs/locale/zh-cn')
dayjs.locale('zh-cn')



//全局修改默认配置，点击空白处不能关闭弹窗
ElementUI.Dialog.props.closeOnClickModal.default = false
// ElementUI.Dialog.props.modalAppendToBody.default = false
Vue.use(ElementUI)
Vue.use(uploader)
Vue.use(MetaInfo)
Vue.use(VueI18n);

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.prototype.$api = api

const i18n = new VueI18n({

  locale: 'zh-CN', // 语言标识
  messages: {
      'zh-CN': require('@/assets/common/zh-CN.js'), // 中文语言包
      'en-US': require('@/assets/common/en-US.js'), // 英文语言包
  }
});


router.beforeEach((to, from, next) => {
  if (to.meta.metaInfo) {
    store.commit('CAHNGE_META_INFO', to.meta.metaInfo)
  }
  next() // Allow the route change first
})

new Vue({
  i18n,
  router,
  store,
  metaInfo() {
    return {
      // title: this.$store.state.metaInfo.title,
      meta: [
        {
          property: 'og:title',
          content: this.$store.state.metaInfo.ogTitle
        },
        {
          property: 'og:url',
          content: this.$store.state.metaInfo.ogUrl
        },
        {
          property: 'og:image',
          content: this.$store.state.metaInfo.ogImage
        },
        {
          property: 'og:description',
          content: this.$store.state.metaInfo.ogDescription
        }
      ]
    }
  },
  render: (h) => h(App),
  methods: {
    // updateMetaTags(meta) {
    //   const ogTitle = meta.ogTitle || 'Wisemodel 始智AI 社区';
    //   const ogDescription = meta.ogDescription || '始智AI wisemodel.cn社区将从打造中国版huggingface开始，逐步建设成huggingface之外最活跃的中立开放的AI开源社区，汇聚模型、数据集等AI开源技术资源，并在此在打造新一代AI基础设施服务平台，为AI产业发展提供有力的平台支撑，让AI更简单，促进AI开源创新生态繁荣发展。';
    //   const ogImage = meta.ogImage || 'https://wisemodel-avatar.bj.bcebos.com/favicon.ico';
    //   const ogUrl= meta.ogUrl || 'https://wisemodel.cn/';
    //   let ogTitleMeta = document.querySelector('meta[property="og:title"]');
    //   if (ogTitleMeta) {
    //     ogTitleMeta.setAttribute('content', ogTitle);
    //   }
    //   let ogDescriptionMeta = document.querySelector('meta[property="og:description"]');
    //   if (ogDescriptionMeta) {
    //     ogDescriptionMeta.setAttribute('content', ogDescription);
    //   }
    //   let ogImageMeta = document.querySelector('meta[property="og:image"]');
    //   if (ogImageMeta) {
    //     ogImageMeta.setAttribute('content', ogImage);
    //   }
    //   let ogUrlMeta = document.querySelector('meta[property="og:ogurl"]');
    //   if (ogUrlMeta) {
    //     ogUrlMeta.setAttribute('content', ogUrl);
    //   }
    // }
  },
  mounted() {
    // this.updateMetaTags(router.currentRoute.meta);
  }
}).$mount('#app')

// 全局设置 elementUI的$message的默认显示时间
function initElementMessage() {
  const messages = ['success', 'warning', 'info', 'error']
  messages.forEach((type) => {
    ElementUI.Message[type] = (options) => {
      if (typeof options === 'string') {
        options = {
          message: options
        }
        // 默认配置
        options.duration = 1500
        // options.showClose = true;
      }
      options.type = type
      return ElementUI.Message(options)
    }
  })
}
