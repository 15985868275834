<template>
  <div id="app">
    <router-view />
    <!-- 将上传组件全局注册 -->
    <global-uploader />
  </div>
</template>

<script>
import GlobalUploader from '/vue-simple-uploader/GlobalUploader'
import { init } from '@/utils/getSelectOptions'
export default {
  components: {
    GlobalUploader
  },
  data() {
    return {}
  },
  created() {
   
    if( sessionStorage.getItem('languageType')){
      let data = sessionStorage.getItem('languageType')
      if(data==1){
        this.$i18n.locale = 'zh-CN';
      }
      if(data==2){
        this.$i18n.locale = 'en-US';
      }
    }
  },
  mounted() {
    init()
  },
  methods: {}
}
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
</style>
